import { createSlice, type Draft } from '@reduxjs/toolkit'

import postsApi from '../endpoints/posts'
import { selectReducer, unselectReducer } from './reducers'
import { type PostsSlice } from './types'

const initialState = {
  selectedIds: [],
}

export const postsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    select: selectReducer,
    unselect: unselectReducer,
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      postsApi.endpoints.removePosts.matchFulfilled,
      (state: Draft<PostsSlice>, action) => {
        console.log(action, state)
        state.selectedIds = state.selectedIds.filter((id) => id !== undefined)
      },
    ),
})

export default postsSlice.reducer

export const { select, unselect } = postsSlice.actions
