import Autocomplete, { type AutocompleteProps } from '@mui/joy/Autocomplete'
import AutocompleteOption from '@mui/joy/AutocompleteOption'
import Box from '@mui/joy/Box'
import ListItemContent from '@mui/joy/ListItemContent'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import { type ReactElement, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { useProductsQuery } from '../../../store/endpoints/shopify'
import { type ProductsResponse, type WithRequired } from '../../../types'
import { Spinner } from '../../../ui'
import { Picture } from '../../views'

type NotEmptyProductResponse = WithRequired<ProductsResponse, 'id' | 'title'>

interface ProductSelectProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<string, Multiple, DisableClearable, FreeSolo> {
  sourceAccountId: string
}

const ProductAutocomplete = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  sourceAccountId,
  options,
  value,
  ...props
}: ProductSelectProps<Multiple, DisableClearable, FreeSolo>): ReactElement => {
  const [inputValue, setInputValue] = useState('')

  const [debouncedInputValue] = useDebounce(inputValue, 400)

  // const isSelectedFromOptions = value !== undefined && value !== null && Number.isFinite(+value)

  const {
    data: products = [],
    isLoading,
    isFetching,
  } = useProductsQuery(
    {
      account: sourceAccountId,
      title: debouncedInputValue.length < 3 ? undefined : debouncedInputValue,
    },
    { skip: sourceAccountId === undefined || sourceAccountId === '' },
  )

  const notEmptyProducts = products.filter(
    (product): product is NotEmptyProductResponse =>
      product.id !== undefined && product.title !== undefined,
  )

  const productsMap = new Map(notEmptyProducts.map((product) => [product.id, product]))

  return (
    <Autocomplete
      loading={isLoading || isFetching}
      loadingText={
        <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
          <Spinner size="sm" />
        </Box>
      }
      options={notEmptyProducts.map(({ id }) => id)}
      getOptionLabel={(productId) =>
        typeof productId === 'string' ? productsMap.get(productId)?.title ?? '' : ''
      }
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderOption={(props, productId) => {
        const product = productsMap.get(productId)
        return (
          <AutocompleteOption {...props}>
            <ListItemDecorator sx={{ mr: 2.5, display: 'flex', justifyContent: 'center' }}>
              <Picture size={40} src={product?.image ?? ''} />
            </ListItemDecorator>
            <ListItemContent sx={{ py: 1.5, fontSize: 'sm' }}>{product?.title}</ListItemContent>
          </AutocompleteOption>
        )
      }}
      value={value}
      {...props}
    />
  )
}

export default ProductAutocomplete
