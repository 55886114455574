import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Typography from '@mui/joy/Typography'
import type { ChangeEvent, ReactElement } from 'react'
import { TbUpload } from 'react-icons/tb'

import { AnimationBox } from './AnimationBox'

interface AddInputLikeButtonProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const UploadInput = ({ onChange }: AddInputLikeButtonProps): ReactElement => {
  return (
    <AnimationBox color="primary">
      <Button
        variant="outlined"
        component="label"
        sx={{
          height: 68,
          width: 68,
          // color: 'primary',
          // ':hover': {
          //   backgroundColor: 'transparent',
          //   borderWidth: 2,
          //   borderColor: (theme) => theme.palette.primary[500],
          // },
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography sx={{ display: 'contents' }} color="primary">
            <TbUpload size={24} />
          </Typography>

          <Typography
            color="primary"
            component="span"
            sx={{
              fontSize: 9,
              lineHeight: '20px',
              letterSpacing: 0.585,
              fontWeight: 600,
            }}
          >
            UPLOAD
          </Typography>
        </Box>

        <input type="file" hidden onChange={onChange} />
      </Button>
    </AnimationBox>
  )
}
