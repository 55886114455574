/* eslint-disable @typescript-eslint/no-invalid-void-type */
import type { LottieMediaResponse, RegularMediaResponse } from '../../types'
import api from '../api'

const mediaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<LottieMediaResponse | RegularMediaResponse, FormData>({
      query: (body) => ({
        url: 'api/media/file',
        method: 'POST',
        body,
      }),
    }),
    uploadUrl: builder.mutation<LottieMediaResponse | RegularMediaResponse, { url: string }>({
      query: (body) => ({
        url: 'api/media/url',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useUploadFileMutation, useUploadUrlMutation } = mediaApi

export default mediaApi
