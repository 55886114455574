import { useEffect, useState } from 'react'

import { usePostsStatisticsQuery } from '../store/endpoints/posts'
import { type PostResponse } from '../types'

const usePublishingPosts = (): PostResponse[] => {
  const { data: postsStatistics } = usePostsStatisticsQuery()

  const [publishingPosts, setPublishingPosts] = useState<PostResponse[]>([])

  useEffect(() => {
    const publishing = postsStatistics?.PUBLISHING ?? 0

    if (publishing > 0) {
      const eventSource = new EventSource('/api/posts/sse/subscribe', { withCredentials: true })

      eventSource.addEventListener('post-update', (event) => {
        const newPublishingPost = JSON.parse(event.data) as Partial<PostResponse>

        if ('status' in newPublishingPost && 'id' in newPublishingPost) {
          setPublishingPosts((prevPosts: PostResponse[]): PostResponse[] => {
            return [
              ...prevPosts.filter((post: PostResponse) => post.id !== newPublishingPost.id),
              newPublishingPost,
            ]
          })
        }
      })

      return () => {
        eventSource.close()
      }
    }
  }, [postsStatistics?.PUBLISHING])

  return publishingPosts
}

export default usePublishingPosts
