import Button from '@mui/joy/Button'
import FormControl from '@mui/joy/FormControl'
import Input from '@mui/joy/Input'
import type { FC, PropsWithChildren, SyntheticEvent } from 'react'
import { IoSearchSharp } from 'react-icons/io5'

import { PostTypeSelect, SocialAccountSelect } from '../../../../components/forms'
import { Bar } from '../../../../components/views'
import { type PostType } from '../../../../types'

interface PostsBarProps extends PropsWithChildren {
  socialAccountId: string | null
  postType: PostType | null
  search: string | null
  onNewPostClick: () => void
  onSocialAccountChange: (newValue: string | null) => void
  onPostTypeChange: (newValue: string | null) => void
  onSearchChange: (newValue: string | null) => void
}

const PostsBar: FC<PostsBarProps> = ({
  socialAccountId,
  postType,
  search,
  onNewPostClick,
  onPostTypeChange,
  onSocialAccountChange,
  onSearchChange,
  children,
}) => {
  const handleSearchChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const newValue = (event.target as HTMLInputElement).value
    if (newValue.length >= 2) {
      onSearchChange(newValue)
    }
    if (newValue === '') {
      onSearchChange(null)
    }
  }

  const handlePostTypeChange = (_: unknown, postType: PostType | 'ANY' | null): void => {
    onPostTypeChange(postType === 'ANY' ? null : postType)
  }

  const handleSocialChange = (_: unknown, socialId: string | null): void => {
    onSocialAccountChange(socialId === 'ANY' ? null : socialId)
  }
  return (
    <Bar>
      <FormControl size="md">
        <SocialAccountSelect
          prefix="Page is "
          sx={{ width: 195 }}
          size="md"
          defaultValue=""
          value={socialAccountId ?? 'ANY'}
          onChange={handleSocialChange}
        />
      </FormControl>
      <FormControl size="md">
        <PostTypeSelect
          sx={{ width: 195 }}
          size="md"
          value={postType ?? 'ANY'}
          onChange={handlePostTypeChange}
        />
      </FormControl>
      {children}
      <FormControl size="md" sx={{ ml: 'auto' }}>
        <Input
          placeholder="Search"
          defaultValue={search ?? ''}
          startDecorator={<IoSearchSharp size={18} />}
          onChange={handleSearchChange}
        />
      </FormControl>
      <Button size="md" onClick={onNewPostClick}>
        New Post
      </Button>
    </Bar>
  )
}

export default PostsBar
