import * as yup from 'yup'

const postsFormSchema = yup.object({
  posts: yup
    .array()
    .of(
      yup.object({
        socialName: yup.string().oneOf(['FACEBOOK', 'INSTAGRAM']).required(),
        socialAccountId: yup.string().required('Required'),
        postType: yup.string().oneOf(['POST', 'STORY', 'REEL']).required('Required'),
        productId: yup.string().required('Required'),
        media: yup
          .array()
          .of(
            yup.object({
              id: yup.string().required(),
              url: yup.string(),
            }),
          )
          .min(1, 'Field must have at least 1 items')
          .required(),
        caption: yup.string().max(2200, 'Field must be at most 2200 characters'),
      }),
    )
    .min(1)
    .required(),
})

export { postsFormSchema }
