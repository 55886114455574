import Stack from '@mui/joy/Stack'
import type { ChangeEvent, ReactElement } from 'react'
import { useEffect, useState } from 'react'
import type { Control, FieldPath, FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { useUploadFileMutation, useUploadUrlMutation } from '../../store/endpoints/media'
import { useProductQuery } from '../../store/endpoints/shopify'
import { type MediaValue } from '../../types/types'
import { AttachedMedia } from './AttachedMedia'
import { MediaLoading } from './MediaLoading'
import { UploadInput } from './UploadInput'

interface MediaProps<T extends FieldValues> {
  name: FieldPath<T>
  control: Control<T>
  sourceAccountId?: string
  productId?: string
  error?: boolean
}

const isUploadedPicture = (picture: Partial<MediaValue>): picture is MediaValue =>
  picture.id !== undefined

const Media = <T extends FieldValues>({
  name,
  control,
  sourceAccountId,
  productId,
}: MediaProps<T>): ReactElement => {
  const { field } = useController({ control, name })
  // selected pictures storage as part of form state
  const selectedMedias = (field.value as MediaValue[] | undefined) ?? []
  const selectedMediasIds = selectedMedias.map(({ id }) => id)

  // unselected pictures save as part of component state
  const [unSelectedMedias, setUnSelectedMedias] = useState<Array<Partial<MediaValue>>>([])

  const [uploadFile, { isLoading }] = useUploadFileMutation()

  const [uploadUrl, { isLoading: isLoadingProduct }] = useUploadUrlMutation()

  const { data: productData } = useProductQuery(
    { sourceAccountId: sourceAccountId ?? '', productId: productId ?? '' },
    {
      skip:
        sourceAccountId === '' ||
        sourceAccountId === undefined ||
        productId === '' ||
        productId === undefined,
    },
  )

  useEffect(() => {
    if (productData?.images !== undefined && Array.isArray(productData?.images)) {
      const selectedMediasUrls = selectedMedias.map(({ url }) => url)
      const productMedias = productData.images.filter((url) => !selectedMediasUrls.includes(url))

      // FIX it !!! different urls bc off links
      console.log(selectedMediasUrls, productMedias)

      setUnSelectedMedias((currentMedias) => {
        const currentMediasUrls = currentMedias.map(({ url }) => url)

        const newMedias = productMedias
          .filter((mediaUrl) => !currentMediasUrls.includes(mediaUrl))
          .map((mediaUrl) => ({ url: mediaUrl }))
        return [...currentMedias, ...newMedias]
      })
    }
  }, [productData])

  const isSelectedMedia = (id: string): boolean => selectedMediasIds.includes(id)

  const select = (media: MediaValue): void => {
    // picture from product
    field.onChange([...selectedMedias, media])
    setUnSelectedMedias((currentUnSelectedPictures) =>
      currentUnSelectedPictures.filter(({ id }) => id !== media.id),
    )
  }

  const removeFromUnSelected = (urlForRemove: string): void => {
    setUnSelectedMedias((currentUnSelectedPictures) =>
      currentUnSelectedPictures.filter(({ url }) => url !== urlForRemove),
    )
  }

  const unSelect = (pictureForUnSelect: MediaValue): void => {
    field.onChange(selectedMedias.filter(({ id }) => id !== pictureForUnSelect.id))
    setUnSelectedMedias((currentUnSelectedPictures) => [
      ...currentUnSelectedPictures,
      pictureForUnSelect,
    ])
  }

  const createRemoveHandler = (pictureForRemove: Partial<MediaValue>) => (): void => {
    if (isUploadedPicture(pictureForRemove)) {
      if (isSelectedMedia(pictureForRemove.id)) {
        field.onChange(selectedMedias.filter(({ id }) => id !== pictureForRemove.id))
      } else {
        setUnSelectedMedias((previousPictures) =>
          previousPictures.filter(({ id }) => id !== pictureForRemove.id),
        )
      }
    } else {
      if (pictureForRemove.url !== undefined) {
        removeFromUnSelected(pictureForRemove.url)
      }
    }
  }

  const createClickHandler = (picture: Partial<MediaValue>) => (): void => {
    if (isUploadedPicture(picture)) {
      isSelectedMedia(picture.id) ? unSelect(picture) : select(picture)
    } else {
      if (picture.url !== undefined) {
        removeFromUnSelected(picture.url)
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        setTimeout(async (): Promise<void> => {
          if (picture.url !== undefined) {
            const result = await uploadUrl({ url: picture.url })
            if ('data' in result) {
              const { url, id } = result.data

              if (id !== undefined) {
                const newValue: MediaValue = { id, url }
                select(newValue)
              }
            }
          }
        })
      }
    }
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const target = event.nativeEvent.target as HTMLInputElement
    const files = target.files
    if (files !== null) {
      const length = files.length
      for (let index = 0; index < length; index += 1) {
        const file = files.item(index)
        if (file != null) {
          const formData = new FormData()
          formData.append('file', file)
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          setTimeout(async (): Promise<void> => {
            const result = await uploadFile(formData)

            if ('data' in result) {
              const { url, id } = result.data

              if (id !== undefined) {
                const newValue: MediaValue = { id, url }
                field.onChange(field.value === undefined ? [newValue] : [...field.value, newValue])
              }
            }
          })
        }
      }
    }
  }

  const renderPicture = (picture: Partial<MediaValue>, index: number): ReactElement => {
    const key = picture.id === undefined ? picture.id : picture.url
    const keyOrIndex = key ?? `${index}`
    return (
      <AttachedMedia
        key={keyOrIndex}
        index={selectedMediasIds.findIndex((pictureId) => pictureId === picture.id)}
        url={picture.url}
        name={keyOrIndex}
        selected={isUploadedPicture(picture) ? selectedMediasIds.includes(picture.id) : false}
        onClick={createClickHandler(picture)}
        onRemovePicture={createRemoveHandler(picture)}
      />
    )
  }

  return (
    <Stack gap={1.25} direction="row" flexWrap="wrap">
      {selectedMedias.map(renderPicture)}
      {(isLoading || isLoadingProduct) && <MediaLoading />}
      {unSelectedMedias.map(renderPicture)}
      {!isLoading && <UploadInput onChange={handleInputChange} />}
    </Stack>
  )
}

export default Media
