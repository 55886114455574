/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, IconButton, Stack, TabPanel, Textarea } from '@mui/joy'
import Button from '@mui/joy/Button'
import Tab from '@mui/joy/Tab'
import TabList from '@mui/joy/TabList'
import Tabs, { type TabsProps } from '@mui/joy/Tabs'
import { type FC, useState } from 'react'
import { Controller } from 'react-hook-form'
import { TbPlus, TbX } from 'react-icons/tb'

import { AIDescriptionTools, TemplateTools } from '../../../../components/ai'

interface SocialAccountTabsProps extends TabsProps {
  control: any
  name: string
  sourceAccountId: string
}

const TemplatesTabs: FC<SocialAccountTabsProps> = ({
  children,
  defaultValue,
  name,
  control,
  sourceAccountId,
  ...props
}) => {
  // const [activeTab, setActiveTab] = useState(0)
  const [tabs, setTabs] = useState<number[]>([0])

  const createRemoveHandler = (tabForRemove: number) => (): void => {
    setTabs((currentTabs) => currentTabs.filter((tab) => tab !== tabForRemove))
  }

  const addHandler = (): void => {
    setTabs((currentTabs) => [...currentTabs, currentTabs[currentTabs.length - 1] + 1])
  }

  return (
    <Box>
      <Tabs variant="outlined" defaultValue={0} {...props}>
        <TabList
          disableUnderline
          sx={{
            height: 44,
            gap: 2,
            borderRadius: 'sm',
            mb: 2,
            overflow: 'auto',
            scrollSnapType: 'x mandatory',
            display: 'flex',
            // '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {tabs.map((tab: number) => (
            <Tab
              variant="outlined"
              key={tab}
              sx={{
                width: 125,
                borderRadius: 'sm',
                textWrap: 'nowrap',
                flex: 'none',
                scrollSnapAlign: 'start',
              }}
              component="div"
              disableIndicator
              value={tab}
            >
              Varian {tab + 1}
              {tabs.length !== 1 && (
                <IconButton
                  sx={{
                    '--variant-plainHoverBg': 'var(--joy-palette-primary-outlinedHoverBg)',
                  }}
                  size="xs"
                  onClick={createRemoveHandler(tab)}
                >
                  <TbX size={28} />
                </IconButton>
              )}
            </Tab>
          ))}

          <Button color="neutral" variant="outlined" onClick={addHandler}>
            <TbPlus />
          </Button>
        </TabList>
        {tabs.map((tab: number) => (
          <TabPanel value={tab} sx={{ p: 0 }} key={tab}>
            <Stack gap={1}>
              <Controller
                name={`${name}.${tab}`}
                control={control}
                render={({ field }) => {
                  return (
                    <>
                      <TemplateTools
                        text={field.value ?? ''}
                        onTextChange={(newValue) => {
                          field.onChange(newValue)
                        }}
                      />
                      <Box sx={{ position: 'relative' }}>
                        <Box sx={{ position: 'absolute', zIndex: 1, p: '12px' }}>
                          <AIDescriptionTools
                            sourceAccountId={sourceAccountId}
                            accountPlatform={'FACEBOOK'}
                            description={field.value ?? ''}
                            onChange={(newDescription: string): void => {
                              field.onChange(newDescription)
                            }}
                          />
                        </Box>
                        <Textarea
                          // error={postTemplatesError !== undefined}
                          sx={{ pt: 6 }}
                          minRows={3}
                          placeholder="Enter or generate caption..."
                          value={field.value ?? ''}
                          onChange={(newValue) => {
                            field.onChange(newValue)
                          }}
                        />
                      </Box>
                    </>
                  )
                }}
              />
            </Stack>
          </TabPanel>
        ))}
      </Tabs>
    </Box>
  )
}

export default TemplatesTabs
