import AspectRatio, { type AspectRatioProps } from '@mui/joy/AspectRatio'
import Box from '@mui/joy/Box'
import { type FC, useState } from 'react'

import { Spinner } from '..'

interface PictureProps extends AspectRatioProps {
  src: string
  alt?: string
  size?: number
}

const Picture: FC<PictureProps> = ({ src, size = 60, alt, sx, ...props }) => {
  const [isLoading, setIsLoading] = useState(true)

  const handleLoad = (): void => {
    setIsLoading(false)
  }

  return (
    <AspectRatio
      flex
      ratio={1}
      sx={{
        borderRadius: 'sm',
        height: size,
        width: size,
        ...sx,
      }}
      {...props}
    >
      {isLoading && (
        <Box display="flex">
          <Spinner size="md" />
        </Box>
      )}
      <img
        width={size}
        height={size}
        style={{ display: isLoading ? 'none' : 'inline-block' }}
        alt={alt}
        src={src}
        onLoad={handleLoad}
      />
    </AspectRatio>
  )
}

export default Picture
