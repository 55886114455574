import { yupResolver } from '@hookform/resolvers/yup'
import { TabPanel } from '@mui/joy'
import { type FC } from 'react'
import { useForm } from 'react-hook-form'

import SocialAccountsTabs from '../../../../../../components/tabs/SocialAccountsTabs/SocialAccountsTabs'
import BoxWithBar from '../../../../../../components/views/BoxWithBar/BoxWithBar'
import FormCard from '../../../../../../components/views/FormCard/FormCard'
import PreviewBox from '../../../../../../components/views/PreviewBox/PreviewBox'
import { TransparentForm } from '../../../../../../components/views/TransparentForm/TransparentForm'
import useSocialAccountsForms from '../../../../../../hooks/useSocialAccountForms/useSocialAccountsForms'
import { type NotEmptySocialAccount } from '../../../../../../hooks/useSocialAccounts'
import PostsFormBar from '../PostsFormBar/PostsFormBar'
import PostForm from './components/PostForm/PostForm'
import { postsFormSchema } from './PostsForm.schema'
import { type PostsFormProps, type PostsFormValues } from './PostsForm.types'

const PostsForm: FC<PostsFormProps> = ({
  defaultValues,
  socialAccounts,
  renderPostFormPreview,
  onSubmit,
}) => {
  const defaultSocialAccountId = defaultValues?.posts?.[0]?.socialAccountId

  const {
    control,
    handleSubmit,
    watch,
    formState: { isLoading, isSubmitting },
  } = useForm<PostsFormValues>({
    defaultValues,
    resolver: yupResolver<PostsFormValues>(postsFormSchema),
  })

  const { activeId, setActiveId, appendForm, removeForm } = useSocialAccountsForms({
    defaultSocialAccountId,
    control: control as any,
    name: 'posts',
  })

  const postForms = watch('posts')

  const handleActiveChange = (_: unknown, socialAccountId: number | string | null): void => {
    if (typeof socialAccountId === 'number') {
      return
    }

    setActiveId(socialAccountId ?? '')
  }

  const handleTabAdd = (socialAccount: NotEmptySocialAccount): void => {
    appendForm({
      socialAccountId: socialAccount.id,
      socialName: socialAccount.platform,
      postType: 'POST',
      media: [],
    })
  }

  const handleTabRemove = (socialAccountId: string): void => {
    removeForm(socialAccountId)
  }

  return (
    <TransparentForm onSubmit={handleSubmit(onSubmit)}>
      <BoxWithBar bar={<PostsFormBar isSubmitting={isSubmitting} />}>
        <SocialAccountsTabs
          variant="soft"
          socialAccounts={socialAccounts}
          defaultValue={defaultSocialAccountId}
          value={activeId}
          onChange={handleActiveChange}
          onTabAdded={handleTabAdd}
          onTabRemoved={handleTabRemove}
        >
          {!isLoading &&
            postForms.map((postForm, index) => (
              <TabPanel
                key={postForm.socialAccountId}
                value={postForm.socialAccountId}
                variant="soft"
              >
                <PreviewBox
                  preview={
                    <>
                      {renderPostFormPreview !== undefined
                        ? renderPostFormPreview(postForm)
                        : 'Post preview unavailable'}
                    </>
                  }
                >
                  <FormCard>
                    <PostForm control={control} index={index} />
                  </FormCard>
                </PreviewBox>
              </TabPanel>
            ))}
        </SocialAccountsTabs>
      </BoxWithBar>
    </TransparentForm>
  )
}

export default PostsForm
