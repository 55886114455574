/* eslint-disable @typescript-eslint/no-invalid-void-type */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import type { TagDescription } from '@reduxjs/toolkit/query'

import type {
  FacebookPostCreateRequest,
  FacebookPostUpdateRequest,
  InstagramPostCreateRequest,
  InstagramPostUpdateRequest,
  PagePostResponse,
  PostProduct,
  PostResponse,
} from '../../types'
import api from '../api'

// remove required title from Product approved back
type PatchProduct<T> = T extends { product?: PostProduct }
  ? Omit<T, 'product'> & { product?: Pick<PostProduct, 'id'> }
  : T

interface Draftable<T> {
  draft?: boolean
  body: T
}

const postsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    posts: builder.query<PagePostResponse, string>({
      query: (params: string) => ({
        url: 'api/posts',
        params: new URLSearchParams(params),
      }),
      providesTags: (result: PagePostResponse | undefined) =>
        Array.isArray(result?.content)
          ? [
              ...((result as PagePostResponse).content as PostResponse[]).reduce<
                Array<TagDescription<'Post'>>
              >((cache, { id }) => {
                if (id !== undefined) {
                  cache.push({ type: 'Post' as const, id })
                }
                return cache
              }, []),
            ]
          : [{ type: 'Post', id: 'LIST' }],
    }),
    post: builder.query<PostResponse, string>({
      query: (postId: string) => `/api/posts/${postId}`,
    }),
    createInstagramPost: builder.mutation<
      void,
      Draftable<PatchProduct<InstagramPostCreateRequest>>
    >({
      query: ({ draft, body }) => ({
        url: `api/posts/instagram`,
        method: 'POST',
        params: { draft },
        body,
      }),
      invalidatesTags: ['Post'],
    }),
    createFacebookPost: builder.mutation<void, Draftable<PatchProduct<FacebookPostCreateRequest>>>({
      query: ({ draft, body }) => ({
        url: `api/posts/facebook`,
        method: 'POST',
        params: { draft },
        body,
      }),
      invalidatesTags: ['Post'],
    }),
    editFacebookPost: builder.mutation<void, Draftable<PatchProduct<FacebookPostUpdateRequest>>>({
      query: ({ draft, body }) => ({
        url: `api/posts/facebook`,
        method: 'PUT',
        params: { draft },
        body,
      }),
      invalidatesTags: ['Post'],
    }),
    editInstagramPost: builder.mutation<void, Draftable<PatchProduct<InstagramPostUpdateRequest>>>({
      query: ({ draft, body }) => ({
        url: 'api/posts/instagram',
        method: 'PUT',
        params: { draft },
        body,
      }),
      invalidatesTags: ['Post'],
    }),
    removePosts: builder.mutation<void, string | string[]>({
      query: (postIdOrIds) =>
        Array.isArray(postIdOrIds)
          ? {
              url: 'api/posts',
              method: 'DELETE',
              body: { ids: postIdOrIds },
            }
          : {
              url: `api/posts/${postIdOrIds}`,
              method: 'DELETE',
            },
      invalidatesTags: (result, error, postIdOrIds) =>
        Array.isArray(postIdOrIds)
          ? postIdOrIds.map((id) => ({ type: 'Post', id }))
          : [{ type: 'Post', id: postIdOrIds }],
    }),
    postsStatistics: builder.query<Record<string, number>, void>({
      keepUnusedDataFor: 0,
      query: () => ({
        method: 'GET',
        url: 'api/posts/statistics',
      }),
    }),
  }),
})

export const {
  usePostsQuery,
  useCreateInstagramPostMutation,
  useLazyPostQuery,
  usePostQuery,
  useEditInstagramPostMutation,
  useCreateFacebookPostMutation,
  useRemovePostsMutation,
  usePostsStatisticsQuery,
  useLazyPostsQuery,
} = postsApi

export default postsApi
