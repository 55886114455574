import {
  PostPreview as AmploriumPostPreview,
  PostPreviewThemeProvider,
} from '@amplorium/post-preview'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { type PostPreviewProps } from './PostPreview.type'

const PostPreview: FC<PostPreviewProps> = ({ post }) => {
  return (
    <ErrorBoundary
      fallback={
        <Typography variant="soft" color="danger">
          Ooops, preview unavailable
        </Typography>
      }
    >
      <PostPreviewThemeProvider>
        <AmploriumPostPreview post={post as any} />
      </PostPreviewThemeProvider>
    </ErrorBoundary>
  )
}

export default PostPreview
