import { type FC, useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import useSocialAccount from '../../../hooks/useSocialAccount'
import { useGetTextPreviewQuery } from '../../../store/endpoints/postsTemplates'
import { useProductQuery } from '../../../store/endpoints/shopify'
import { POST_DESCRIPTION_DEBOUNCE_TIME } from '../../../utils/constants'
import { isValidSocialType } from '../../../utils/typesHelper'
import PostPreview from '../../views/PostPreview/PostPreview'
import { type PostPostPreviewContainerProps } from './PostPostPreviewContainer.type'

const PostPostPreviewContainer: FC<PostPostPreviewContainerProps> = ({
  socialAccountId,
  postType,
  media,
  description,
  sourceAccountId = '',
  productId = '',
}) => {
  const { socialAccount } = useSocialAccount(socialAccountId)

  const { data: product } = useProductQuery(
    { sourceAccountId, productId },
    { skip: socialAccountId === '' || productId === '' },
  )

  const [debouncedDescription] = useDebounce(description, POST_DESCRIPTION_DEBOUNCE_TIME)

  const { data: { description: richDescription } = {} } = useGetTextPreviewQuery(
    { template: debouncedDescription ?? '', product: product ?? {} },
    {
      skip:
        debouncedDescription === undefined ||
        debouncedDescription === null ||
        product === undefined ||
        debouncedDescription?.length < 3,
    },
  )

  const post = useMemo(
    () => ({
      postType,
      media,
      description: richDescription ?? description ?? '',
      account: {
        name: socialAccount?.name ?? '',
        url: socialAccount?.picture ?? '',
      },
      socialType: isValidSocialType(socialAccount?.platform) ? socialAccount?.platform : undefined,
    }),
    [postType, richDescription, description, socialAccount, media],
  )

  return <PostPreview post={post} />
}

export default PostPostPreviewContainer
