import { type FC } from 'react'

import { type CampaignStatus } from '../../../types'
import ActiveCampaignTable from '../components/ActiveCampaignTable/ActiveCampaignTable'
import PausedCampaignTable from '../components/PausedCampaignTable/PausedCampaignTable'
import { type CampaignTableProps } from '../types'

const COMPONENTS: Record<CampaignStatus, FC<CampaignTableProps>> = {
  ACTIVE: ActiveCampaignTable,
  PAUSED: PausedCampaignTable,
}

export function useCampaignTable(status: CampaignStatus): FC<CampaignTableProps> {
  if (status in COMPONENTS) {
    return COMPONENTS[status]
  }

  throw new Error('Unsupported status')
}
