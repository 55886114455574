import styled from '@emotion/styled'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import DialogActions from '@mui/joy/DialogActions'
import DialogContent from '@mui/joy/DialogContent'
import Link from '@mui/joy/Link'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import Sheet from '@mui/joy/Sheet'
import Typography from '@mui/joy/Typography'
import { type FC, useState } from 'react'
import { TbExternalLink } from 'react-icons/tb'

import { useAppDispatch } from '../../../../store'
import { pushErrorNotification } from '../../../../store/notifications/actions'
import { type PostResponse } from '../../../../types'
import Spinner from '../../../../ui/Spinner/Spinner'
import { type PostTableProps } from '../../types'
import BasePostsTable from '../base/BasePostsTable'

const ResponsiveIframe = styled('iframe')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
`

const SentPostsTable: FC<PostTableProps> = (props) => {
  const [postPreviewLink, setPostPreviewLink] = useState('')
  const [isIframeLoading, setIsIframeLoading] = useState(true)
  const dispatch = useAppDispatch()

  const handleClosePostPreview = (): void => {
    setPostPreviewLink('')
    setIsIframeLoading(true)
  }

  const handleRowClick = (post: PostResponse): void => {
    if (post?.postLink !== undefined && post?.postLink !== '') {
      setPostPreviewLink(post?.postLink)
    } else {
      dispatch(pushErrorNotification({ message: "Can't open post, post link is empty" }))
    }
  }

  const handleIframeLoad = (): void => {
    setIsIframeLoading(false)
  }

  return (
    <>
      <BasePostsTable {...props} onRowClick={handleRowClick} />
      <Modal open={postPreviewLink !== ''} onClose={handleClosePostPreview}>
        <ModalDialog variant="plain">
          <Box display="flex">
            <ModalClose />
            <Typography sx={{ fontSize: 24, fontWeight: 600 }}>Post preview</Typography>
          </Box>
          <DialogContent sx={{ position: 'relative' }}>
            {isIframeLoading && (
              <Box
                sx={{
                  width: '470px',
                  height: '470px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spinner size="lg" />
              </Box>
            )}
            <Sheet
              variant="outlined"
              sx={{
                display: isIframeLoading ? 'none' : 'block',
                position: 'relative',
                width: '470px',
                paddingTop: '150%', // The proportions was used from design
                borderRadius: 'sm',
                overflow: 'hidden',
              }}
            >
              <ResponsiveIframe
                src={`${postPreviewLink}embed`}
                onLoad={handleIframeLoad}
                scrolling="auto"
              />
            </Sheet>
            {!isIframeLoading && (
              <DialogActions>
                <Sheet
                  sx={{
                    p: 2,
                    width: '100%',
                    borderRadius: 'sm',
                    background: 'var(--background-level1)',
                    overflow: 'hidden',
                  }}
                  variant="outlined"
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Button variant="outlined" onClick={handleClosePostPreview}>
                      Cancel
                    </Button>
                    <Link href={postPreviewLink} target="_blank" underline="none">
                      <Button
                        startDecorator={<TbExternalLink />}
                        content="a"
                        href={postPreviewLink}
                      >
                        Visit post
                      </Button>
                    </Link>
                  </Box>
                </Sheet>
              </DialogActions>
            )}
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  )
}

export default SentPostsTable
