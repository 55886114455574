/* eslint-disable @typescript-eslint/no-invalid-void-type */
import type { ConnectFacebookRequest, UserResponse } from '../../types'
import api from '../api'

const rootApi = api.injectEndpoints({
  endpoints: (builder) => ({
    user: builder.query<UserResponse, void>({
      query: () => 'api/user',
      providesTags: ['User'],
    }),
    connectFB: builder.mutation<void, ConnectFacebookRequest>({
      query: (body) => ({
        url: 'api/connect/fb',
        method: 'POST',
        body,
      }),
      // todo: separate to other api
      invalidatesTags: ['User'],
    }),
    deleteSocialAccount: builder.mutation<void, { platform: string; id: string }>({
      query: ({ platform, id }) => ({
        url: `api/connect/${platform}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
  }),
})

export const {
  useUserQuery,
  useConnectFBMutation,
  useLazyUserQuery,
  useDeleteSocialAccountMutation,
} = rootApi

export default rootApi
