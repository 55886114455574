import Alert from '@mui/joy/Alert'
import Box from '@mui/joy/Box'
import FormControl from '@mui/joy/FormControl'
import Input from '@mui/joy/Input'
import RadioGroup from '@mui/joy/RadioGroup'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { type ChangeEvent, type FC } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import {
  TbArrowsRandom,
  TbDatabase,
  TbHistoryToggle,
  TbInfoSquareRounded,
  TbLayersSubtract,
  TbPhoto,
  TbShirt,
  TbSquarePlus,
  TbTag,
} from 'react-icons/tb'

import { CollectionsMultiSelect } from '../../../../components/forms'
import DaysSelect from '../../../../components/forms/DaysSelect/DaysSelect'
import ErrorMessage from '../../../../components/forms/ErrorMessage/ErrorMessage'
import SourceAccountSelectV2 from '../../../../components/forms/SourceAccountSelectV2/SourceAccountSelectV2'
import TagsMultiSelect from '../../../../components/forms/TagsMultiSelect/TagsMultiSelect'
import TimeSelect from '../../../../components/forms/TimeSelect/TimeSelect'
import FormCard from '../../../../components/views/FormCard/FormCard'
import Radio from '../../../../ui/Radio/Radio'
import TemplatesTabs from '../TemplatesTabs/TemplatesTabs'
import { type CampaignFormProps } from './CampaignForm.types'
import SelectionTypeAlert from './SelectionTypeAlert'

const labelsSx = {
  fontWeight: 500,
  color: 'text.primary',
  mb: '6px',
}

const CampaignForm: FC<CampaignFormProps> = ({ index }) => {
  const { register, control } = useFormContext()

  const {
    fieldState: { error: nameError },
  } = useController({ control, name: `campaigns.${index}.name` })

  const {
    field: { value: sourceAccountId },
    fieldState: { error: sourceAccountIdError },
  } = useController({ control, name: `campaigns.${index}.sourceAccountId` })

  const {
    field: { value: selectionType },
  } = useController({ control, name: `campaigns.${index}.type` })

  const {
    fieldState: { error: collectionIdsError },
  } = useController({ control, name: `campaigns.${index}.collectionIds` })

  const {
    fieldState: { error: tagsError },
  } = useController({ control, name: `campaigns.${index}.tags` })

  const {
    fieldState: { error: daysError },
  } = useController({ control, name: `campaigns.${index}.days` })

  const {
    fieldState: { error: timeError },
  } = useController({ control, name: `campaigns.${index}.time` })

  const {
    fieldState: { error: postTemplatesError },
  } = useController({ control, name: `campaigns.${index}.postTemplates.0` })

  const { field: productRuleType } = useController({
    control,
    name: `campaigns.${index}.productRuleType`,
  })

  return (
    <Stack gap={1}>
      <FormCard>
        <Stack gap={4}>
          <Typography level="h3">Campaign details</Typography>

          <FormControl>
            <Typography sx={labelsSx}>Campaign name</Typography>

            <Input
              placeholder="Campaign name"
              error={nameError != null}
              {...register(`campaigns.${index}.name`, { required: true })}
            />
            <ErrorMessage error={nameError} />
          </FormControl>

          <FormControl>
            <Typography sx={labelsSx}>Shop</Typography>

            <Controller
              name={`campaigns.${index}.sourceAccountId`}
              control={control}
              render={({ field, formState, fieldState }) => {
                console.log('ff>', fieldState, formState)
                return (
                  <SourceAccountSelectV2
                    color={sourceAccountIdError !== undefined ? 'danger' : 'neutral'}
                    placeholder="Choose shop"
                    onChange={(_, newValue) => {
                      field.onChange(newValue)
                    }}
                    value={field.value}
                  />
                )
              }}
            />
            <ErrorMessage error={sourceAccountIdError} />
          </FormControl>

          <FormControl>
            <Typography sx={labelsSx}>Selection type</Typography>

            <Controller
              key={`campaigns.${index}`}
              name={`campaigns.${index}.type`}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  value={field.value ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(event.target.value)
                  }}
                  variant="outlined"
                  orientation="horizontal"
                >
                  <Radio
                    overlay
                    disableIcon
                    value="NEW_ITEM"
                    label={
                      <>
                        <TbShirt />
                        <Typography>Newest product</Typography>
                      </>
                    }
                  />
                  <Radio
                    overlay
                    value="RANDOM"
                    disableIcon
                    label={
                      <>
                        <TbArrowsRandom />
                        <Typography>Random Product</Typography>
                      </>
                    }
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          <SelectionTypeAlert selectionType={selectionType} />

          <FormControl>
            <Typography sx={labelsSx}>Selection type</Typography>

            <Controller
              key={`campaigns.${index}`}
              name={`campaigns.${index}.productRuleType`}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  value={field.value ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(event.target.value)
                  }}
                  variant="outlined"
                  orientation="horizontal"
                >
                  <Radio
                    overlay
                    disableIcon
                    value="COLLECTIONS"
                    label={
                      <>
                        <TbDatabase />
                        <Typography>Collection</Typography>
                      </>
                    }
                  />
                  <Radio
                    overlay
                    value="TAGS"
                    disableIcon
                    label={
                      <>
                        <TbTag />
                        <Typography>Tag</Typography>
                      </>
                    }
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          {productRuleType.value === 'COLLECTIONS' && (
            <Box>
              <Typography sx={labelsSx}>Collection</Typography>
              <Box sx={{ flex: 2 }}>
                <Controller
                  name={`campaigns.${index}.collectionIds`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <CollectionsMultiSelect
                        disabled={
                          sourceAccountId === undefined ||
                          sourceAccountId === null ||
                          sourceAccountId === ''
                        }
                        color={collectionIdsError !== undefined ? 'danger' : 'neutral'}
                        sourceAccountId={sourceAccountId}
                        value={field.value ?? []}
                        onChange={(_, newValue) => {
                          field.onChange(newValue)
                        }}
                        multiple
                        placeholder="Choose collection"
                      />
                    )
                  }}
                />
                <ErrorMessage error={collectionIdsError} />
              </Box>
            </Box>
          )}

          {productRuleType.value === 'TAGS' && (
            <Box>
              <Typography sx={labelsSx}>Tags</Typography>
              <Box sx={{ flex: 2 }}>
                <Controller
                  name={`campaigns.${index}.tags`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TagsMultiSelect
                        disabled={
                          sourceAccountId === undefined ||
                          sourceAccountId === null ||
                          sourceAccountId === ''
                        }
                        color={tagsError !== undefined ? 'danger' : 'neutral'}
                        sourceAccountId={sourceAccountId}
                        value={field.value ?? []}
                        onChange={(_, newValue) => {
                          field.onChange(newValue)
                        }}
                        multiple
                        placeholder="Choose tag"
                      />
                    )
                  }}
                />
                <ErrorMessage error={tagsError} />
              </Box>
            </Box>
          )}
        </Stack>
      </FormCard>

      <FormCard>
        <Stack gap={4}>
          <Typography level="h3">Template</Typography>
          <FormControl>
            <Typography sx={labelsSx} id="post-title-label" component="label">
              Title
            </Typography>

            <Controller
              key={`campaigns.${index}`}
              name={`campaigns.${index}.postType`}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  value={field.value ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(event.target.value)
                  }}
                  variant="outlined"
                  orientation="horizontal"
                >
                  <Radio
                    overlay
                    disableIcon
                    value="POST"
                    label={
                      <>
                        <TbSquarePlus />
                        <Typography>Post</Typography>
                      </>
                    }
                  />
                  <Radio
                    overlay
                    value="STORY"
                    disableIcon
                    label={
                      <>
                        <TbHistoryToggle />
                        <Typography>Story</Typography>
                      </>
                    }
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          <FormControl>
            <Typography sx={labelsSx} id="post-title-label" component="label">
              Include media
            </Typography>
            <Controller
              key={`campaigns.${index}`}
              name={`campaigns.${index}.mediaRule`}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  value={field.value ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(event.target.value)
                  }}
                  variant="outlined"
                  orientation="horizontal"
                >
                  <Radio
                    overlay
                    disableIcon
                    value="FIRST"
                    label={
                      <>
                        <TbPhoto />
                        <Typography>Thumbnail image</Typography>
                      </>
                    }
                  />
                  <Radio
                    overlay
                    value="ALL"
                    disableIcon
                    label={
                      <>
                        <TbLayersSubtract />
                        <Typography>Multiple images</Typography>
                      </>
                    }
                  />
                </RadioGroup>
              )}
            />

            <Alert sx={{ mt: 1 }} startDecorator={<TbInfoSquareRounded />}>
              Alternate your post captions by creating up 5 different variants
            </Alert>
          </FormControl>

          <Typography sx={labelsSx} id="post-title-label" component="label">
            Caption
          </Typography>
          <TemplatesTabs
            name={`campaigns.${index}.postTemplates`}
            variant="plain"
            sourceAccountId={sourceAccountId}
            control={control}
          />

          <ErrorMessage error={postTemplatesError} />
        </Stack>
      </FormCard>

      <FormCard>
        <Stack gap={4}>
          <Typography level="h3">Schedule</Typography>

          <Stack direction="row" gap={2} flex={1}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={labelsSx}>Frequency</Typography>
              <Controller
                name={`campaigns.${index}.days`}
                control={control}
                render={({ field }) => {
                  return (
                    <DaysSelect
                      color={daysError !== undefined ? 'danger' : 'neutral'}
                      value={field.value ?? []}
                      onChange={(e, newValue) => {
                        field.onChange(newValue)
                      }}
                      placeholder="Choose frequency"
                      multiple
                    />
                  )
                }}
              />
              <ErrorMessage error={daysError} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormControl>
                <Typography sx={labelsSx}>Time</Typography>
                <Controller
                  name={`campaigns.${index}.time`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TimeSelect
                        color={timeError !== undefined ? 'danger' : 'neutral'}
                        placeholder="Choose time"
                        value={field.value ?? []}
                        onChange={(e, newValue) => {
                          field.onChange(newValue)
                        }}
                      />
                    )
                  }}
                />
              </FormControl>
              <ErrorMessage error={timeError} />
            </Box>
          </Stack>
        </Stack>
      </FormCard>
    </Stack>
  )
}

export default CampaignForm
