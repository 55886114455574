import { type BaseSyntheticEvent } from 'react'

import { type PostFormValues } from './components/PostsForm/components/PostForm/PostForm.types'
import { PostSubmitType } from './PostsFormContainer.types'

export const getSubmitType = (event?: BaseSyntheticEvent): PostSubmitType => {
  const submitterName = ((event?.nativeEvent as SubmitEvent)?.submitter as HTMLButtonElement)?.name

  switch (submitterName) {
    case PostSubmitType.Scheduled:
    case PostSubmitType.Draft:
    case PostSubmitType.Instant:
      return submitterName
    default:
      throw new Error(`Unknown SubmitterName: ${submitterName}`)
  }
}

export const createPostBody = (formValues: PostFormValues): void => {}
