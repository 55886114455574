import { type FC } from 'react'

import NewslettersForm from './components/NewslettersForm/NewslettersForm'

const NewslettersContainer: FC = () => {
  return (
    <NewslettersForm
      defaultValues={{
        marketing: true,
        notification: true,
      }}
      onSubmit={console.log}
    ></NewslettersForm>
  )
}

export default NewslettersContainer
