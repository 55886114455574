import { yupResolver } from '@hookform/resolvers/yup'
import TabPanel from '@mui/joy/TabPanel'
import { type FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import SocialAccountsTabs from '../../../../components/tabs/SocialAccountsTabs/SocialAccountsTabs'
import BoxWithBar from '../../../../components/views/BoxWithBar/BoxWithBar'
import PreviewBox from '../../../../components/views/PreviewBox/PreviewBox'
import { TransparentForm } from '../../../../components/views/TransparentForm/TransparentForm'
import useSocialAccountsForms from '../../../../hooks/useSocialAccountForms/useSocialAccountsForms'
import { type NotEmptySocialAccount } from '../../../../hooks/useSocialAccounts'
import useCampaignsSocialAccounts from '../../hooks/useCampaignsSocialAccounts'
import useIsNewCampaignFlag from '../../hooks/useIsNewCampaignFlag'
import CampaignForm from '../CampaignForm/CampaignForm'
import { type CampaignFormValues } from '../CampaignForm/CampaignForm.types'
import CampaignFormBar from '../CampaignFormBar/CampaignFormBar'
import { campaignsFormSchema } from './CampaignsForm.schema'
import { type CampaignsFormProps, type CampaignsFormValues } from './CampaignsForm.types'

const CampaignsForm: FC<CampaignsFormProps> = ({ defaultValues, onSubmit, renderPreview }) => {
  const defaultSocialAccountId = defaultValues?.campaigns?.[0]?.socialAccountId

  const { control, handleSubmit, watch, formState, ...formRest } = useForm<CampaignsFormValues>({
    defaultValues,
    resolver: yupResolver<CampaignsFormValues>(campaignsFormSchema),
  })

  const { activeId, appendForm, removeForm, setActiveId } = useSocialAccountsForms({
    defaultSocialAccountId,
    control: control as any,
    name: 'campaigns',
  })

  const campaignsForms = watch('campaigns')

  const handleActiveChange = (_: unknown, socialAccountId: number | string | null): void => {
    if (typeof socialAccountId === 'number') {
      return
    }

    setActiveId(socialAccountId ?? '')
  }

  const handleTabAdd = (socialAccount: NotEmptySocialAccount): void => {
    const emptyForm: CampaignFormValues = {
      socialAccountId: socialAccount.id,
      socialName: socialAccount.platform,
      type: 'NEW_ITEM',
      productRuleType: 'COLLECTIONS',
      postType: 'POST',
      mediaRule: 'FIRST',
      postTemplates: [],
      tags: [],
      days: [],
      sourceAccountId: '',
      time: '',
      name: '',
      collectionIds: [],
    }
    appendForm(emptyForm)
  }

  const handleTabRemove = (socialAccountId: string): void => {
    removeForm(socialAccountId)
  }

  const socialAccounts = useCampaignsSocialAccounts()

  const isNewCampaign = useIsNewCampaignFlag()

  console.log('df', formState.isLoading)
  return (
    <TransparentForm onSubmit={handleSubmit(onSubmit)}>
      <FormProvider
        control={control}
        handleSubmit={handleSubmit}
        watch={watch}
        formState={formState}
        {...formRest}
      >
        <BoxWithBar bar={<CampaignFormBar />}>
          <SocialAccountsTabs
            readonly={!isNewCampaign}
            variant="soft"
            socialAccounts={socialAccounts}
            defaultValue={defaultSocialAccountId}
            value={activeId}
            onChange={handleActiveChange}
            onTabAdded={handleTabAdd}
            onTabRemoved={handleTabRemove}
          >
            {!formState.isLoading &&
              campaignsForms.map((campaignForm, index) => (
                <TabPanel
                  key={campaignForm.socialAccountId}
                  value={campaignForm.socialAccountId}
                  variant="soft"
                >
                  <PreviewBox preview={<>{renderPreview?.(campaignForm)}</>}>
                    <CampaignForm index={index} />
                  </PreviewBox>
                </TabPanel>
              ))}
          </SocialAccountsTabs>
        </BoxWithBar>
      </FormProvider>
    </TransparentForm>
  )
}

export default CampaignsForm
