import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import Option from '@mui/joy/Option'
import Select, { type SelectOption, type SelectProps } from '@mui/joy/Select'
import { type ReactElement, type ReactNode } from 'react'

import useSocialAccounts from '../../../hooks/useSocialAccounts'
import { Spinner } from '../../../ui'

interface SocialAccountSelectProps {
  prefix?: string
  hasAny?: boolean
}

const SocialAccountSelect = <Multiple extends boolean = false>({
  prefix = '',
  hasAny = true,
  ...props
}: SelectProps<string, Multiple> & SocialAccountSelectProps): ReactElement => {
  const { socialAccounts, isLoading, isFetching } = useSocialAccounts()

  const renderValue = <Multiple extends boolean>(
    optionOrOptions: Multiple extends true
      ? Array<SelectOption<string>>
      : SelectOption<string> | null,
  ): ReactNode => (
    <>
      {`${prefix}${
        (Array.isArray(optionOrOptions)
          ? optionOrOptions?.map((option) => option.label?.toString())?.join(', ')
          : optionOrOptions?.label?.toString()) ?? 'ANY'
      }`}
    </>
  )

  return (
    <Select renderValue={renderValue} {...props}>
      {hasAny && <Option value="ANY">Any</Option>}

      {(isLoading || isFetching) && (
        <Option value="loading" disabled>
          <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
            <Spinner size="sm" />
          </Box>
        </Option>
      )}

      {socialAccounts
        ?.filter(
          (socialAccount) =>
            socialAccount?.platform === 'INSTAGRAM' || socialAccount?.platform === 'FACEBOOK',
        )
        .map(({ id, name, picture }) => (
          <Option key={id} value={id}>
            <ListItemDecorator>
              <Avatar size="sm" src={picture} />
            </ListItemDecorator>
            {name}
          </Option>
        ))}
    </Select>
  )
}

export default SocialAccountSelect
